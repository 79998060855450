var exports = {};
// autogenerated - 2/12/2023

/*
 *
 * https://www.w3.org/Style/CSS/all-properties.en.html
 */

var implementedProperties = new Set();
implementedProperties.add("azimuth");
implementedProperties.add("background");
implementedProperties.add("background-attachment");
implementedProperties.add("background-color");
implementedProperties.add("background-image");
implementedProperties.add("background-position");
implementedProperties.add("background-repeat");
implementedProperties.add("border");
implementedProperties.add("border-bottom");
implementedProperties.add("border-bottom-color");
implementedProperties.add("border-bottom-style");
implementedProperties.add("border-bottom-width");
implementedProperties.add("border-collapse");
implementedProperties.add("border-color");
implementedProperties.add("border-left");
implementedProperties.add("border-left-color");
implementedProperties.add("border-left-style");
implementedProperties.add("border-left-width");
implementedProperties.add("border-right");
implementedProperties.add("border-right-color");
implementedProperties.add("border-right-style");
implementedProperties.add("border-right-width");
implementedProperties.add("border-spacing");
implementedProperties.add("border-style");
implementedProperties.add("border-top");
implementedProperties.add("border-top-color");
implementedProperties.add("border-top-style");
implementedProperties.add("border-top-width");
implementedProperties.add("border-width");
implementedProperties.add("bottom");
implementedProperties.add("clear");
implementedProperties.add("clip");
implementedProperties.add("color");
implementedProperties.add("css-float");
implementedProperties.add("flex");
implementedProperties.add("flex-basis");
implementedProperties.add("flex-grow");
implementedProperties.add("flex-shrink");
implementedProperties.add("float");
implementedProperties.add("flood-color");
implementedProperties.add("font");
implementedProperties.add("font-family");
implementedProperties.add("font-size");
implementedProperties.add("font-style");
implementedProperties.add("font-variant");
implementedProperties.add("font-weight");
implementedProperties.add("height");
implementedProperties.add("left");
implementedProperties.add("lighting-color");
implementedProperties.add("line-height");
implementedProperties.add("margin");
implementedProperties.add("margin-bottom");
implementedProperties.add("margin-left");
implementedProperties.add("margin-right");
implementedProperties.add("margin-top");
implementedProperties.add("opacity");
implementedProperties.add("outline-color");
implementedProperties.add("padding");
implementedProperties.add("padding-bottom");
implementedProperties.add("padding-left");
implementedProperties.add("padding-right");
implementedProperties.add("padding-top");
implementedProperties.add("right");
implementedProperties.add("stop-color");
implementedProperties.add("text-line-through-color");
implementedProperties.add("text-overline-color");
implementedProperties.add("text-underline-color");
implementedProperties.add("top");
implementedProperties.add("webkit-border-after-color");
implementedProperties.add("webkit-border-before-color");
implementedProperties.add("webkit-border-end-color");
implementedProperties.add("webkit-border-start-color");
implementedProperties.add("webkit-column-rule-color");
implementedProperties.add("webkit-match-nearest-mail-blockquote-color");
implementedProperties.add("webkit-tap-highlight-color");
implementedProperties.add("webkit-text-emphasis-color");
implementedProperties.add("webkit-text-fill-color");
implementedProperties.add("webkit-text-stroke-color");
implementedProperties.add("width");
exports = implementedProperties;
export default exports;